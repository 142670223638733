.header-footer-hidden {
  .page-sticky-footer,
  .responsive-footer,
  a[href='#services'],
  #services {
    display: none;
  }
  .site-content {
    padding-top: 0 !important;
  }
  .site-header {
    &__wrapper {
      display: none;
    }
  }
  .site-footer {
    display: none;
  }
}
